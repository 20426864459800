body {
  font-family: Roboto, sans-serif;
}

.sr-only {
  display: none;
}

/* hide the clear x in Edge as it doesn't fire onChange */
@supports (-ms-ime-align: auto) {
  input::-ms-clear {
    display: none;
  }
}
