.ol-attribution.ol-unselectable.ol-control {
  width: 100%;
  display: flex;
  justify-content: center;
  background: unset;
  max-width: unset;
  height: 1.3em;
  bottom: 0;
  right: 0;
}

.ol-attribution.ol-unselectable.ol-control ul {
  background-color: rgba(255, 255, 255, 0.8);
}

.ol-attribution button {
  display: none;
}
